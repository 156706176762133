import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/ikeaWidget/IkeaProcessWidget.module.scss'
import lightOnImgSrc from 'pages/customWidget/ikea/assets/lamp-light.png'
import lampimgSrc from 'pages/customWidget/ikea/assets/lamp.png'
import { IkeaProcessWidgetData } from 'pages/customWidget/ikea/IkeaProcessWidgetData'
import { Phases } from 'pages/customWidget/ikea/types'

interface Props {
  isFullscreenProcess?: boolean
}

export const IkeaProcessWidget = ({ isFullscreenProcess = true }: Props) => {
  let indexNumber = 0
  const showsOnlyHoveredState = isFullscreenProcess
  const [isHovered, setIsHovered] = useState<boolean>(showsOnlyHoveredState)

  const handlePhaseEnter = () => {
    setIsHovered(true)
  }

  const handlePhaseLeave = () => {
    if (!showsOnlyHoveredState) setIsHovered(false)
  }

  return (
    <div className={clsx({ [styles.root]: isFullscreenProcess })}>
      <Flex
        onMouseEnter={handlePhaseEnter}
        onMouseLeave={handlePhaseLeave}
        className={clsx(styles.container, { [styles.fullscreenContainer]: isFullscreenProcess })}
        direction="column"
        data-testid="ikea-process-widget"
      >
        <WppTypography type="xl-heading" className={styles.title} tag="span" data-testid="ikea-process-title">
          {IkeaProcessWidgetData.title}
        </WppTypography>
        <Flex gap={15}>
          {IkeaProcessWidgetData.phases.map((phase, index: number) => {
            return (
              <Flex key={index} className={styles['phase-container']} direction="column" data-testid="phase-container">
                <div className={styles['image-container']}>
                  <img
                    src={phase.img}
                    className={clsx(styles['phase-image'], { [styles.active]: isHovered })}
                    alt={phase.phaseId}
                  />
                </div>
                <div
                  data-testid="phase-text-container"
                  className={clsx(styles['text-container'], {
                    [styles.active]: isHovered,
                    [styles.fullscreenTextContainer]: isFullscreenProcess,
                  })}
                  style={{ backgroundColor: phase.bgColor, color: phase.color }}
                >
                  <div
                    data-testid="phase-description-container"
                    className={clsx(styles['title-description'], { [styles.show]: isHovered })}
                  >
                    <div className={styles['phase-title']} style={{ color: phase.color }}>
                      {phase.title}
                    </div>
                    <div className={styles['phase-description']} style={{ color: phase.color }}>
                      {phase.description}
                    </div>
                  </div>
                  <div
                    className={clsx(styles['more-info'], { [styles.show]: isHovered })}
                    data-testid="phase-moreInfo-container"
                  >
                    {phase.moreInfo.considerations.map((info: string, ind: number) => {
                      indexNumber++
                      return (
                        <div
                          key={ind}
                          className={clsx(styles['info'], { [styles.show]: phase.phaseId === Phases.Activate })}
                        >
                          <p className={styles.infoText}>{`${indexNumber}. ${info}`}</p>
                          {phase.phaseId === Phases.Activate &&
                            phase.moreInfo.details &&
                            phase.moreInfo.details.map(phase => {
                              return <div className={styles['activate-phase']}>{phase}</div>
                            })}
                        </div>
                      )
                    })}
                  </div>
                  <Flex className={styles['icon-button-container']} justify="end">
                    <Link to={phase.innerPageUrl}>
                      <Flex
                        data-testid="button-container"
                        className={clsx(styles['button-container'], { [styles.show]: isHovered })}
                      >
                        Learn more
                      </Flex>
                    </Link>
                  </Flex>
                </div>
              </Flex>
            )
          })}
        </Flex>
        <div>
          <img className={styles['lamp']} src={lampimgSrc} alt="lamp" />
          <img
            data-testid="lamp-light-container"
            className={clsx(isHovered ? styles['light-on'] : styles['light-off'], styles['lamp-light'], {
              [styles.lampLightFullscreen]: isFullscreenProcess,
            })}
            src={lightOnImgSrc}
            alt="lamp light"
          />
        </div>
      </Flex>
    </div>
  )
}
