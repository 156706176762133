// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KxshW{right:0;left:auto}.KxshW::part(overlay){opacity:0}.KxshW::part(side-modal){background-color:var(--info-bg-color);border-left:1px solid var(--wpp-grey-color-200)}.KxshW::part(header-container){background-color:var(--info-bg-color)}.KxshW::part(actions-wrapper){background-color:var(--info-bg-color)}.KxshW::part(icon-cross){color:var(--info-close-icon-button-color)}.phC16::part(actions-wrapper){border-top:none}.l1jRJ::part(button){color:inherit;border-color:inherit}.lpFDp::part(button):hover{background-color:var(--info-close-button-hover-color)}", "",{"version":3,"sources":["webpack://./src/pages/phaseBuilder/additionalInformation/additionalInformationSideModal/AdditionalInformationSideModal.module.scss"],"names":[],"mappings":"AAAA,OACE,OAAA,CACA,SAAA,CAEA,sBACE,SAAA,CAGF,yBACE,qCAAA,CACA,+CAAA,CAGF,+BACE,qCAAA,CAGF,8BACE,qCAAA,CAGF,yBACE,yCAAA,CAKF,8BACE,eAAA,CAKF,qBACE,aAAA,CACA,oBAAA,CAMA,2BACE,qDAAA","sourcesContent":[".modal {\n  right: 0;\n  left: auto;\n\n  &::part(overlay) {\n    opacity: 0;\n  }\n\n  &::part(side-modal) {\n    background-color: var(--info-bg-color);\n    border-left: 1px solid var(--wpp-grey-color-200);\n  }\n\n  &::part(header-container) {\n    background-color: var(--info-bg-color);\n  }\n\n  &::part(actions-wrapper) {\n    background-color: var(--info-bg-color);\n  }\n\n  &::part(icon-cross) {\n    color: var(--info-close-icon-button-color);\n  }\n}\n\n.actionsBorderless {\n  &::part(actions-wrapper) {\n    border-top: none;\n  }\n}\n\n.closeBtn {\n  &::part(button) {\n    color: inherit;\n    border-color: inherit;\n  }\n}\n\n.closeBtnHover {\n  &::part(button) {\n    &:hover {\n      background-color: var(--info-close-button-hover-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "KxshW",
	"actionsBorderless": "phC16",
	"closeBtn": "l1jRJ",
	"closeBtnHover": "lpFDp"
};
export default ___CSS_LOADER_EXPORT___;
